import { viewport } from '../utils/viewport';
import throttle from 'lodash.throttle';

function init() {
    const header = document.querySelector('.js-header');

    /**
     * Показать/скрыть хедер по скроллу
     */
    let prevScrollY = window.scrollY;
    let deltaScrollY = 0;

    const onScroll = () => {
        if (header) {
            const scrollY = Math.min(window.scrollY, document.documentElement.scrollHeight - viewport.height);
            deltaScrollY = Math.abs(scrollY - prevScrollY);

            if (deltaScrollY > 20) {
                if (scrollY > prevScrollY && scrollY >= 0) {
                    header.classList.add('is-hidden');
                    document.documentElement.classList.add('header-hidden');
                } else {
                    header.classList.remove('is-hidden');
                    document.documentElement.classList.remove('header-hidden');
                }
            }

            prevScrollY = scrollY;
        }
    };

    document.addEventListener('scroll', throttle(onScroll, 100));
}

export default { init };
