import { LitPopupElement } from '@/custom-elements/LitPopupElement/LitPopupElement';
import { debounce } from 'lodash';
import gsap from 'gsap';
import { isMobileDevice } from '../utils/mobile';

function onPageFirstVisit(pageName: string, callback: () => void) {
    if (!localStorage.getItem(pageName)) {
        callback();
        localStorage.setItem(pageName, 'visited');
    }
}

function init() {
    const detailClass = document.querySelector('.js-detail-page');
    const helloPopup = document.querySelector<LitPopupElement>('app-lit-popup[data-lit-popup="first-visit"]');
    const priceEl = document.querySelector<HTMLElement>('.js-detail-price');
    const helloInner = document.querySelector<HTMLElement>('.js-hello-inner');
    let offsetY = 0;
    let offsetLeft = 0;
    let helloHeight = 0;

    const onResize = () => {
        if (helloPopup && priceEl && helloInner) {
            offsetY = Math.round(priceEl.getBoundingClientRect().height + priceEl.offsetTop);
            offsetLeft = Math.round(priceEl.getBoundingClientRect().left);
            helloHeight = Math.round(helloInner.getBoundingClientRect().height);
            helloPopup.setAttribute('style', `--offset-y: ${offsetY - scrollY}px; --offset-x: ${offsetLeft}px`);
        }
    };

    const debouncedOnResize = debounce(onResize, 50);

    window.addEventListener('resize', debouncedOnResize);

    if (detailClass && helloPopup && priceEl) {
        onPageFirstVisit('detail', () => {
            onResize();
            priceEl.classList.add('is-on-top');
            gsap.to(window, {
                duration: 0.75,
                scrollTo: () => {
                    if (isMobileDevice()) {
                        return offsetY - 100;
                    } else {
                        return 0;
                    }
                },
                ease: 'power3.out',
                onComplete: () => {
                    onResize();
                    helloPopup.open();
                },
            });
        });

        helloPopup.addEventListener('close', () => {
            priceEl.classList.remove('is-on-top');
        });
    }
}

const _module = { init };

export default _module;
