import 'nouislider/dist/nouislider.css';
import noUiSlider, { API } from 'nouislider';
import { toCurrency } from '../utils/to-currency';

const map = new WeakMap<HTMLElement, API>();

function init(_container: HTMLElement | Document = document) {
    const rangeSliders = Array.from(_container.querySelectorAll<HTMLElement>('.js-range-slider-el'));
    rangeSliders.forEach((el) => {
        const container = el.closest<HTMLElement>('.js-range-slider');
        const fromEl = container?.querySelector<HTMLElement>('.js-range-slider-from');
        const toEl = container?.querySelector<HTMLElement>('.js-range-slider-to');
        const min = parseFloat(el.dataset.min || '0');
        const max = parseFloat(el.dataset.max || '0');
        const inputFrom = container?.querySelector<HTMLInputElement>('input.js-range-slider-from');
        const inputTo = container?.querySelector<HTMLInputElement>('input.js-range-slider-to');
        const minValue = parseFloat(inputFrom?.value || '0');
        const maxValue = parseFloat(inputTo?.value || '0');
        const step = parseFloat(el.dataset.step || '1');
        const format = container?.dataset.format || 'number';

        const formatValue = (value: string | number) => {
            switch (format) {
                case 'USD':
                    return toCurrency(Number(value), 'en-US', 'USD');
                case 'EUR':
                    return toCurrency(Number(value), 'en-US', 'EUR');
                case 'RUB':
                    return toCurrency(Number(value), 'ru', 'RUB');
                default:
                    return Number(value);
            }
        };

        const slider = noUiSlider.create(el, {
            connect: true,
            start: [minValue, maxValue],
            range: {
                min: [min],
                max: [max],
            },
            step,
        });
        map.set(el, slider);

        slider.on('update', (values) => {
            const [from, to] = values;

            if (fromEl) {
                fromEl.textContent = `${formatValue(from)}`;
            }
            if (toEl) {
                toEl.textContent = `${formatValue(to)}`;
            }

            el.dispatchEvent(new CustomEvent('range-slider-update', { detail: [from, to] }));
        });

        slider.on('change', (values) => {
            const [from, to] = values;

            if (inputFrom) {
                inputFrom.value = `${from}`;
            }

            if (inputTo) {
                inputTo.value = `${to}`;
            }
        });
    });
}

function getInstanceByElement(el: HTMLElement | null) {
    return el ? map.get(el) : null;
}

export default { init, getInstanceByElement };
