function init() {
    const bookAnAppointmentBtn = document.querySelector('.js-detail-book-an-appointment-btn');
    const inCartBtn = document.querySelector('.js-product-cart-wrapper');
    const buttonsWrapper = document.querySelector('.js-detail-buttons');

    if (buttonsWrapper) {
        const clonedBookAnAppointmentBtn = bookAnAppointmentBtn?.cloneNode(true);
        if (clonedBookAnAppointmentBtn) {
            buttonsWrapper.appendChild(clonedBookAnAppointmentBtn);
        }

        const clonedInCartBtn = inCartBtn?.cloneNode(true);
        if (clonedInCartBtn) {
            buttonsWrapper.appendChild(clonedInCartBtn);
        }
    }
}

const _module = { init };

export default _module;
