import debounce from 'lodash.debounce';
import axios, { toFormData } from 'axios';
import createFormSender, { AjaxFormSender } from './ajax-form-sender';
import createValidator from '../../modules/validator';
import { removeButtonLoading, setButtonLoading } from '../utils/loading-btn';
import { clearAntispamInput, hideFormMessages, showFailureMessage, showFormMessages } from './ajax-forms';
import masks from './imask';
import { getNoun } from '../utils/strings';

async function init() {
    const form = document.querySelector<HTMLFormElement>('.js-login-form');
    const loginFormPhoneInput = document.querySelector<HTMLInputElement>('input.js-login-form-phone-input');
    const loginPhoneNumber = document.querySelector<HTMLElement>('.js-login-form-phone-number');
    const loginStepEl = document.querySelector<HTMLElement>('.js-login-form-popup-container');
    const smsForm = document.querySelector<HTMLFormElement>('form.js-sms-form');
    const smsStepEl = document.querySelector<HTMLElement>('.js-sms-form-popup-container');
    const closeSmsFormBtn = document.querySelector<HTMLElement>('.js-close-sms-form');
    const smsFormInput = document.querySelector<HTMLInputElement>('input.js-sms-form-input');
    const smsFormRemainingSeconds = document.querySelector<HTMLElement>('.js-sms-form-remaining-seconds');
    const smsFormResubmission = document.querySelector<HTMLElement>('.js-sms-form-resubmission');
    const smsFormResendCodeBtn = document.querySelector<HTMLElement>('.js-sms-form-resend-code');

    const setRemainingSeconds = (seconds: number) => {
        if (smsFormRemainingSeconds) {
            smsFormRemainingSeconds.textContent = `${seconds} ${getNoun(seconds, 'second', 'seconds', 'seconds')}`;
        }
    };

    let loginFormSender: AjaxFormSender | null;

    if (form) {
        let isSubmitting = false;
        let hideTimeout: NodeJS.Timeout;
        const validator = createValidator(form, {
            scrollToInvalidInputOptions: {
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            },
        });

        const shouldClearInputs = !form.classList.contains('do-not-clear');
        const sender = createFormSender(form, {
            shouldClearInputs,
            headers: {
                ['X-Requested-With']: 'XMLHttpRequest',
            },
            onBeforeSend: () => {
                clearAntispamInput(form);
            },
            onSuccess: ({ status, message }) => {
                if (status === 'success') {
                    if (currentRemainingSeconds === 0) {
                        smsFormValid = true;
                    }

                    if (loginFormPhoneInput && loginPhoneNumber) {
                        loginPhoneNumber.textContent = loginFormPhoneInput.value.trim();
                    }

                    if (loginStepEl) {
                        loginStepEl.hidden = true;
                        setTimeout(() => {
                            smsFormInput?.focus();
                        }, 50);
                    }

                    if (smsStepEl) {
                        smsStepEl.hidden = false;
                    }
                } else {
                    smsFormValid = false;
                    showFailureMessage(form, message);
                }
            },
            onError: (err) => {
                smsFormValid = false;
                showFailureMessage(form, err.message || 'Something went wrong');
            },
            onComplete: () => {
                clearTimeout(hideTimeout);
                showFormMessages(form);
                hideTimeout = setTimeout(() => hideFormMessages(form), 5000);
            },
        });
        loginFormSender = sender;

        function submitFn(event: Event) {
            if (isSubmitting) return;
            event.preventDefault();

            const isFormValid = validator.validate();
            const submitBtn = form?.querySelector<HTMLButtonElement>('button[type="submit"]');

            if (isFormValid) {
                isSubmitting = true;

                if (submitBtn) {
                    setButtonLoading(submitBtn);
                }

                sender.send().finally(() => {
                    isSubmitting = false;

                    if (submitBtn) {
                        removeButtonLoading(submitBtn);
                    }
                });
            }
        }

        function onFocus(this: HTMLInputElement) {
            const inputGroup = this.closest<HTMLElement>('.input-group');

            if (inputGroup) {
                validator.clearInput(inputGroup);
            }
        }

        validator.inputGroups.forEach((inputGroup) => {
            const input = inputGroup.querySelector<HTMLInputElement>(
                'input[name]:not([type="submit"]):not([type="reset"]):not([type="hidden"]), select[name]:not([type="submit"]):not([type="reset"]):not([type="hidden"]), textarea[name]:not([type="submit"]):not([type="reset"]):not([type="hidden"])',
            );

            input?.addEventListener('focus', onFocus);
        });
        form.addEventListener('submit', submitFn);
    }

    closeSmsFormBtn?.addEventListener('click', () => {
        if (loginStepEl) {
            loginStepEl.hidden = false;
        }

        if (smsStepEl) {
            smsStepEl.hidden = true;
            setTimeout(() => {
                loginFormPhoneInput?.focus();
            }, 50);
        }
    });

    let isSmsSubmitting = false;
    let resubmissionInterval: NodeJS.Timeout;
    let currentRemainingSeconds = 0;
    let smsFormValid = false;

    const onSmsFormSubmit = async () => {
        const phoneInputMask = masks.getInstanceByElement(smsFormInput);

        if (phoneInputMask && phoneInputMask.value.length === 4 && smsFormInput) {
            if (isSmsSubmitting) return;

            clearTimeout(resubmissionInterval);
            smsFormInput.disabled = true;

            if (smsForm) {
                const submitBtn = smsForm?.querySelector<HTMLButtonElement>('button[type="submit"]');

                isSmsSubmitting = true;

                if (submitBtn) {
                    setButtonLoading(submitBtn);
                }

                try {
                    const { data } = await axios.post(
                        smsForm.action,
                        toFormData({ [smsFormInput.name]: smsFormInput.value.trim() }),
                    );

                    if (data.status === 'success') {
                        if (data.data.redirectUrl) {
                            window.location.href = data.data.redirectUrl;
                        }
                    } else {
                        smsFormValid = false;
                        showFailureMessage(smsForm, data.message || 'Something went wrong');
                        currentRemainingSeconds = data.data.remainingSeconds;
                        setRemainingSeconds(currentRemainingSeconds);

                        if (smsFormResubmission) {
                            smsFormResubmission.hidden = false;
                        }

                        if (smsFormResendCodeBtn) {
                            smsFormResendCodeBtn.hidden = true;
                        }

                        resubmissionInterval = setInterval(() => {
                            currentRemainingSeconds -= 1;

                            if (currentRemainingSeconds === 0) {
                                clearInterval(resubmissionInterval);

                                if (smsFormResubmission) {
                                    smsFormResubmission.hidden = true;
                                }

                                if (smsFormResendCodeBtn) {
                                    smsFormResendCodeBtn.hidden = false;
                                }
                            } else {
                                setRemainingSeconds(currentRemainingSeconds);
                            }
                        }, 1000);
                    }
                } catch (err) {
                    showFailureMessage(smsForm, err instanceof Error ? err.message : 'Something went wrong');
                    throw err;
                } finally {
                    isSmsSubmitting = false;

                    if (smsFormInput) {
                        smsFormInput.disabled = false;
                    }

                    if (submitBtn) {
                        removeButtonLoading(submitBtn);
                    }

                    showFormMessages(smsForm);
                }
            }
        }
    };

    const debouncedOnSmsFormInputChange = debounce(onSmsFormSubmit, 200);

    smsFormInput?.addEventListener('input', () => {
        if (currentRemainingSeconds === 0 && smsFormValid) {
            debouncedOnSmsFormInputChange();
        }
    });

    smsForm?.addEventListener('submit', (event) => {
        event.preventDefault();

        if (smsFormValid) {
            onSmsFormSubmit();
        }
    });

    smsFormResendCodeBtn?.addEventListener('click', () => {
        if (smsFormInput) {
            smsFormInput.value = '';
        }

        if (smsForm) {
            hideFormMessages(smsForm);
        }

        if (smsFormResubmission) {
            smsFormResubmission.hidden = true;
        }

        if (smsFormResendCodeBtn) {
            smsFormResendCodeBtn.hidden = true;
        }

        loginFormSender?.send();
    });
}

export default { init };
