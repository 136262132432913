import { LitPopupElement } from '@/custom-elements/LitPopupElement/LitPopupElement';
import { isMobileDevice } from '../utils/mobile';

const HEADER_MENU_OPENED_CLASS = 'menu-opened';
const HEADER_MENU_CONTENT_OPENED_CLASS = 'menu-content-opened';
const HEADER_BANNER_HIDDEN_CLASS = 'is-banner-hidden';
const TOGGLER_ACTIVE_CLASS = 'is-active';
const CONTENT_ACTIVE_CLASS = 'is-visible';
const NO_SCROLL_CLASS = 'no-scroll';

function init() {
    const searchPopupEl = document.querySelector<LitPopupElement>('app-lit-popup[data-lit-popup="search"]');
    const header = document.querySelector('.js-header');
    const headerBanner = document.querySelector('.js-header-banner');
    const headerCloseContentButtons = Array.from(document.querySelectorAll('.js-header-close-menu-content'));
    const headerCloseContentOverlay = document.querySelector('.js-header-close-menu-content-overlay');
    const menuOpenBtn = document.querySelector('.js-menu-btn');
    const menuCloseBtn = document.querySelector('.js-menu-close-btn');
    const menuTogglers = Array.from(document.querySelectorAll<HTMLElement>('.js-header-menu-btn'));
    const menuLinks = Array.from(document.querySelectorAll<HTMLElement>('.js-header-menu-link'));
    const menuContents = Array.from(document.querySelectorAll<HTMLElement>('.js-header-menu-content'));
    const menuContentWrapper = document.querySelector<HTMLElement>('.js-header-bottom-inner');
    let closingTimeout: NodeJS.Timeout;

    const closeMenuContent = () => {
        header?.classList.remove(HEADER_MENU_CONTENT_OPENED_CLASS);
        menuTogglers.forEach((togglerEl) => {
            togglerEl.classList.remove(TOGGLER_ACTIVE_CLASS);
        });
        closingTimeout = setTimeout(
            () => {
                menuContents.forEach((contentEl) => {
                    contentEl.classList.remove(CONTENT_ACTIVE_CLASS);
                });
                menuContentWrapper?.scrollTo({ top: 0, behavior: 'auto' });
            },
            matchMedia('(max-width: 1024px)').matches ? 300 : 0,
        );
    };

    const closeMenu = () => {
        clearTimeout(closingTimeout);
        header?.classList.remove(HEADER_MENU_OPENED_CLASS);
        document.documentElement.classList.remove(NO_SCROLL_CLASS);
        closeMenuContent();
    };

    searchPopupEl?.addEventListener('open', closeMenu);

    let mouseleaveTimeout: NodeJS.Timeout;

    menuTogglers.forEach((el) => {
        const openMenu = () => {
            clearTimeout(closingTimeout);
            const { name } = el.dataset;

            header?.classList.add(HEADER_MENU_CONTENT_OPENED_CLASS);
            menuTogglers.forEach((togglerEl) => {
                togglerEl.classList.remove(TOGGLER_ACTIVE_CLASS);
            });
            el.classList.add(TOGGLER_ACTIVE_CLASS);
            menuContents.forEach((contentEl) => {
                contentEl.classList.remove(CONTENT_ACTIVE_CLASS);
            });
            menuContents.find((contentEl) => contentEl.dataset.name === name)?.classList.add(CONTENT_ACTIVE_CLASS);
        };

        el.addEventListener('click', openMenu);
        el.addEventListener('mouseenter', () => {
            clearTimeout(mouseleaveTimeout);
            mouseleaveTimeout = setTimeout(() => {
                openMenu();
            }, 350);
        });
        el.addEventListener('mouseleave', (event) => {
            const relatedTarget = event.target as HTMLElement;
            if (
                !relatedTarget.closest('.js-header-menu') &&
                !relatedTarget.closest('.js-header-bottom-inner') &&
                !document.documentElement.classList.contains('search-popup-opened')
            ) {
                closeMenu();
            }
        });
        document.querySelector('.js-header-menu')?.addEventListener('mouseleave', closeMenu);
    });
    menuContents.forEach((el) => {
        el.addEventListener('mouseenter', () => {
            clearTimeout(closingTimeout);
            clearTimeout(mouseleaveTimeout);
            header?.classList.add(HEADER_MENU_CONTENT_OPENED_CLASS);
            el.classList.add(CONTENT_ACTIVE_CLASS);
        });
    });
    headerCloseContentOverlay?.addEventListener('mouseenter', () => {
        if (!document.documentElement.classList.contains('search-popup-opened')) {
            closeMenu();
        }
    });
    menuLinks.forEach((el) => {
        el.addEventListener('mouseenter', () => {
            if (!document.documentElement.classList.contains('search-popup-opened') && !isMobileDevice()) {
                closeMenu();
            }
        });
    });

    headerCloseContentButtons.forEach((el) => {
        el.addEventListener('click', closeMenuContent);
    });

    menuOpenBtn?.addEventListener('click', () => {
        searchPopupEl?.close();
        header?.classList.add(HEADER_MENU_OPENED_CLASS);

        if (matchMedia('(max-width: 1024px)').matches) {
            document.documentElement.classList.add(NO_SCROLL_CLASS);
        }
    });

    menuCloseBtn?.addEventListener('click', closeMenu);

    const onResize = () => {
        const rect = headerBanner?.getBoundingClientRect();

        if (rect) {
            document.documentElement.style.setProperty('--header-banner-height', `${rect.height}px`);
        }
    };

    onResize();
    window.addEventListener('resize', onResize);

    document.addEventListener('scroll', () => {
        const { scrollY } = window;

        if (header) {
            if (scrollY > 20) {
                header.classList.add(HEADER_BANNER_HIDDEN_CLASS);
                document.documentElement.classList.add('header-banner-hidden');
            } else {
                header.classList.remove(HEADER_BANNER_HIDDEN_CLASS);
                document.documentElement.classList.remove('header-banner-hidden');
            }
        }
    });
}

export default { init };
