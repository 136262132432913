function init() {
    const deliveryRadios = Array.from(document.querySelectorAll<HTMLInputElement>('.js-delivery-radio'));
    const courierContent = document.querySelector<HTMLElement>('.js-delivery-content-courier');
    const pickupContent = document.querySelector<HTMLElement>('.js-delivery-content-pickup');
    const pickupSelect = document.querySelector<HTMLSelectElement>('.js-pickup-select');
    const deliveryInput = document.querySelector<HTMLInputElement>('.js-delivery-input');

    if (courierContent && pickupContent && deliveryInput && pickupSelect) {
        deliveryRadios.forEach((radio) => {
            radio.addEventListener('change', (event) => {
                const eventTarget = event.target as HTMLInputElement;

                if (eventTarget.dataset.value === 'pickup') {
                    pickupContent.removeAttribute('hidden');
                    pickupSelect.setAttribute('required', '');

                    courierContent.setAttribute('hidden', '');
                    deliveryInput.removeAttribute('required');
                }

                if (eventTarget.dataset.value === 'courier') {
                    courierContent.removeAttribute('hidden');
                    deliveryInput.setAttribute('required', '');

                    pickupContent.setAttribute('hidden', '');
                    pickupSelect.removeAttribute('required');
                }
            });
        });
    }
}

const _module = { init };

export default _module;
