import './webpack-imports';
import './sentry';
import './custom-elements';
import { applyPolyfills } from './polyfills';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';
import dropdown from './components/dropdown';
import notifications from './components/notifications';
import navbar from './inits/nav-bar';
import headerMenu from './inits/header-menu';
import ajaxForms from './inits/ajax-forms';
import dialogs from './inits/dialog';
import mainDiscountPopup from './inits/main-discount-popup';
import productsSlider from './inits/products-slider';
import favourites from './inits/favourites';
import rangeSliders from './inits/range-sliders';
import catalog from './inits/catalog';
import scrollTo from './inits/scrollto';
import searchFilter from './inits/search-filter';
import filterCollapse from './inits/filter-collapse';
import detailSlider from './inits/detail-slider';
import searchPopup from './inits/search-popup';
import detailCardHello from './inits/detail-card-hello';
import basket from './inits/basket';
import notifyOnDiscount from './inits/notify-on-discount';
import detailAdaptive from './inits/detail-adaptive';
import faqTabs from './inits/faq-tabs';
import masks from './inits/imask';
import aboutSlider from './inits/about-slider';
import login from './inits/login';
import orderPopup from './inits/order-popup';
import select from './inits/select';
import deliverySelect from './inits/delivery-select';
import promocode from './inits/promocode';
import cartCancelItem from './inits/cart-cancel-item';
import orderForm from './inits/order-form';

document.documentElement.classList.add('js-ready');

applyPolyfills().then(() => {
    calculateScrollbarWidth();
    notifications.init();
    dropdown.init();
    navbar.init();
    headerMenu.init();
    ajaxForms.init();
    dialogs.init();
    mainDiscountPopup.init();
    productsSlider.init();
    favourites.init();
    basket.init();
    notifyOnDiscount.init();
    rangeSliders.init();
    catalog.init();
    searchFilter.init();
    filterCollapse.init();
    scrollTo.init();
    detailSlider.init();
    searchPopup.init();
    detailCardHello.init();
    detailAdaptive.init();
    faqTabs.init();
    masks.init();
    aboutSlider.init();
    login.init();
    orderPopup.init();
    select.init();
    deliverySelect.init();
    promocode.init();
    cartCancelItem.init();
    orderForm.init();
});
