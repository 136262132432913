import delegate from 'delegate';
import { pushNotification } from '../components/notifications';
import ContentList from '../components/content-list';
import ContentListFilter from '../components/content-list/modules/ContentListFilter';
import rangeSliders from './range-sliders';
import searchFilter from './search-filter';
import filterCollapse from './filter-collapse';
import { setButtonLoading, removeButtonLoading } from '../utils/loading-btn';

function init() {
    const header = document.querySelector<HTMLElement>('.js-header');
    const scrollToEl = document.querySelector<HTMLElement>('.js-catalog-scrollto-el');
    const catalogEl = document.querySelector<HTMLElement>('.js-catalog');
    const resultsCountEls = Array.from(document.querySelectorAll<HTMLElement>('.js-catalog-results-count'));
    const resultsCategoryEls = Array.from(document.querySelectorAll<HTMLElement>('.js-catalog-results-category'));
    let filterContainer = document.querySelector<HTMLElement>('.js-catalog-form-wrapper');
    let filterWrapper = document.querySelector<HTMLElement>('.js-catalog-filter-wrapper');
    const sortSelect = document.querySelector<HTMLInputElement | HTMLSelectElement>('.js-catalog-sort-select');
    const loaderContainer = document.querySelector<HTMLElement>('.js-catalog-loader-container');
    const bottomEl = document.querySelector<HTMLElement>('.js-catalog-bottom');
    const loadMoreBtn = document.querySelector<HTMLAnchorElement>('a.js-catalog-load-more-btn');
    const totalLoadedEl = document.querySelector<HTMLElement>('.js-catalog-total-loaded');
    const filtersAmountEl = document.querySelector<HTMLElement>('.js-catalog-filter-amount');
    let submitBtn = filterWrapper?.querySelector<HTMLButtonElement>('button.js-catalog-form-submit-btn');
    let priceRangeSliderEl = filterContainer?.querySelector<HTMLElement>('.js-catalog-price-range-slider');
    let inputFrom = filterContainer?.querySelector<HTMLInputElement>('input.js-range-slider-from');
    let inputTo = filterContainer?.querySelector<HTMLInputElement>('input.js-range-slider-to');
    let catalogResetBtns = Array.from(document.querySelectorAll<HTMLElement>('.js-catalog-reset-btn'));

    const initPriceRangeSliderEvents = (contentListInstance: ContentList) => {
        if (priceRangeSliderEl) {
            const filterModule = contentListInstance.modules.find((m) => m instanceof ContentListFilter);
            const rangeSliderInstance = rangeSliders.getInstanceByElement(priceRangeSliderEl);

            if (rangeSliderInstance) {
                rangeSliderInstance.on('change', () => {
                    if (filterModule?.data.filterOnInputChange) {
                        filterModule._debouncedOnFilterChange();
                    }
                });
            }
        }
    };

    if (catalogEl) {
        const catalogCL = new ContentList(catalogEl, {
            modules: filterContainer ? [ContentListFilter] : [],
            pushState: false,
            cacheRequests: false,
            // scrollTo: {
            //     el: scrollToEl || catalogEl,
            //     offset: (header ? -header.getBoundingClientRect().height : 0) - 30,
            // },
            filter: {
                el: filterContainer,
                filterOnInputChange: true,
            },
            onFetchStart: () => {
                catalogCL?.abortFetchContent();
                loaderContainer?.classList.add('is-visible');

                if (submitBtn) {
                    setButtonLoading(submitBtn);
                }
            },
            onFetchSuccess: async (instance, response) => {
                if (response.status === 'error') {
                    pushNotification(response.message, true);
                } else {
                    if (response.data) {
                        if (typeof response.data.results === 'number') {
                            resultsCountEls.forEach((el) => {
                                el.innerHTML = response.data.results;
                            });
                        }

                        if (response.data.category) {
                            resultsCategoryEls.forEach((el) => {
                                el.innerHTML = response.data.category;
                            });
                        }

                        if (response.data.contentHtml && instance.contentEl) {
                            if (typeof response.data.page === 'number' && response.data.page > 1) {
                                instance.contentEl.innerHTML += response.data.contentHtml;
                            } else {
                                instance.contentEl.innerHTML = response.data.contentHtml;
                            }
                        }

                        if (response.data.totalLoaded && totalLoadedEl) {
                            totalLoadedEl.textContent = response.data.totalLoaded;
                        }

                        if (response.data.nextPageUrl) {
                            if (bottomEl) {
                                bottomEl.hidden = false;
                            }

                            if (loadMoreBtn) {
                                loadMoreBtn.href = response.data.nextPageUrl;

                                if (response.data.endpoint) {
                                    loadMoreBtn.dataset.endpoint = response.data.endpoint;
                                }
                            }
                        } else if (bottomEl) {
                            bottomEl.hidden = true;
                        }

                        if (response.data.filterHtml && filterContainer) {
                            filterContainer.innerHTML = response.data.filterHtml;

                            setTimeout(() => {
                                filterContainer = document.querySelector<HTMLElement>('.js-catalog-form-wrapper');
                                filterWrapper = document.querySelector<HTMLElement>('.js-catalog-filter-wrapper');
                                submitBtn = filterWrapper?.querySelector<HTMLButtonElement>(
                                    'button.js-catalog-form-submit-btn',
                                );
                                inputFrom =
                                    filterContainer?.querySelector<HTMLInputElement>('input.js-range-slider-from');
                                inputTo = filterContainer?.querySelector<HTMLInputElement>('input.js-range-slider-to');
                                priceRangeSliderEl = filterContainer?.querySelector<HTMLElement>(
                                    '.js-catalog-price-range-slider',
                                );
                                catalogResetBtns = Array.from(document.querySelectorAll('.js-catalog-reset-btn'));

                                if (filterContainer) {
                                    rangeSliders.init(filterContainer);
                                    initPriceRangeSliderEvents(instance);
                                    searchFilter.init(filterContainer);
                                    filterCollapse.init(filterContainer);
                                }
                            }, 1);
                        }

                        if (response.data.nextUrl) {
                            window.history.pushState(null, '', response.data.nextUrl);
                        }
                    }
                }
            },
            onFetchError: (instance, error) => {
                pushNotification(error.message, true);
            },
            onFetchComplete: () => {
                loaderContainer?.classList.remove('is-visible');

                if (submitBtn) {
                    removeButtonLoading(submitBtn);
                }
            },
        });

        function setResetButtonsState(formDataObject: Record<string, any>) {
            const hiddenInputsNames = (
                filterContainer
                    ? Array.from(
                          filterContainer.querySelectorAll<HTMLInputElement>(
                              'input[type="hidden"]:not(.js-range-slider-from):not(.js-range-slider-to)',
                          ),
                      )
                    : []
            ).map((input) => input.name.replace('[]', ''));

            hiddenInputsNames.forEach((name) => {
                delete formDataObject[name];
            });

            if (priceRangeSliderEl && inputFrom && inputTo) {
                const { min, max } = priceRangeSliderEl.dataset;

                if (
                    inputFrom &&
                    inputFrom.name in formDataObject &&
                    formDataObject[inputFrom.name].split('.')[0] === min
                ) {
                    delete formDataObject[inputFrom.name];
                }

                if (inputTo && inputTo.name in formDataObject && formDataObject[inputTo.name].split('.')[0] === max) {
                    delete formDataObject[inputTo.name];
                }

                if (sortSelect && sortSelect.name in formDataObject) {
                    delete formDataObject[sortSelect.name];
                }
            }

            const pickedFieldsLength = Object.keys(formDataObject).length;
            const isResettable = pickedFieldsLength > 0;

            catalogResetBtns.forEach((el) => {
                el.hidden = !isResettable;
            });

            if (filtersAmountEl) {
                filtersAmountEl.innerHTML = `(${pickedFieldsLength})`;
                filtersAmountEl.hidden = !isResettable;
            }
        }

        filterContainer?.addEventListener('filter-change', (event) => {
            const formDataObject = event.detail;
            setResetButtonsState(formDataObject);
        });

        const filterModule = catalogCL.modules.find((m) => m instanceof ContentListFilter);

        if (filterModule) {
            const formDataObject = filterModule.getFormDataObject();

            if (formDataObject) {
                setResetButtonsState(formDataObject);
            }
        }

        const onResize = () => {
            if (filterModule) {
                filterModule.data.filterOnInputChange = !window.matchMedia('(max-width: 1024px)').matches;
            }
        };

        onResize();
        window.addEventListener('resize', onResize);

        initPriceRangeSliderEvents(catalogCL);

        sortSelect?.addEventListener('change', (event: any) => {
            const sortInput = filterContainer?.querySelector<HTMLInputElement>(`input[name="${sortSelect.name}"]`);

            if (sortInput) {
                sortInput.value = event.target.value;
                filterModule?._onFilterChange();
            }
        });

        const resetFilter = () => {
            if (priceRangeSliderEl) {
                const rangeSliderInstance = rangeSliders.getInstanceByElement(priceRangeSliderEl);
                const { min, max } = priceRangeSliderEl.dataset;

                if (rangeSliderInstance && min && max) {
                    rangeSliderInstance.set([min, max]);
                    if (inputFrom) {
                        inputFrom.value = `${min}`;
                    }

                    if (inputTo) {
                        inputTo.value = `${max}`;
                    }
                }
            }

            filterModule?.resetFilter();
        };

        delegate(document, '.js-catalog-reset-btn', 'click', resetFilter);
    }

    delegate(document, '.js-catalog-filter-open-btn', 'click', () => {
        filterWrapper?.classList.add('is-opened');
        document.documentElement.classList.add('catalog-filter-opened', 'no-scroll');
    });

    delegate(document, '.js-catalog-filter-close-btn', 'click', () => {
        filterWrapper?.classList.remove('is-opened');
        document.documentElement.classList.remove('catalog-filter-opened', 'no-scroll');
    });
}

export default { init };
