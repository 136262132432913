import delegate from 'delegate';

export const removeNotification = (el: HTMLElement) => {
    const container = document.querySelector<HTMLElement>('.js-notifications-list');
    el.classList.add('is-leaving');

    setTimeout(() => {
        try {
            container?.removeChild(el);
        } catch (err) {}
    }, 300);
};

export const pushNotification = (message: string, isError = false) => {
    const container = document.querySelector<HTMLElement>('.js-notifications-list');
    const notificationWrapper = document.createElement('div');
    notificationWrapper.className = 'notification-wrapper';
    const template = `
        <div class="notification js-notification ${isError ? 'notification--danger' : ''}">
            <div class="notification__text">${message}</div>
            <button class="notification-remove-btn app-icon js-remove-notification" aria-label="Remove notification">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 1L8 8M8 8L1 15M8 8L1 1M8 8L15 15" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
    `;
    notificationWrapper.innerHTML = template;
    container?.appendChild(notificationWrapper);

    setTimeout(() => {
        removeNotification(notificationWrapper);
    }, 5000);
};

function init() {
    delegate(document, '.js-remove-notification', 'click', (event: any) => {
        const target = event.delegateTarget as HTMLElement;
        const notificationWrapper = target.closest<HTMLElement>('.notification-wrapper');

        if (notificationWrapper) {
            removeNotification(notificationWrapper);
        }
    });
}

export default { init };
