/**
 * Компонент каталога
 */

import EventEmitter from 'eventemitter3';
import delegate from 'delegate';
import { abortableFetch, AbortableFetch } from './utils/abortable-fetch';
import { getOffsetTop } from './utils/dom';
import type {
    ContentListModule,
    ContentListHooks,
    FilterParams,
    SortingParams,
    InfiniteScrollingParams,
} from './types';

interface ContentListData extends ContentListHooks {
    cacheRequests: boolean;
    filter?: Partial<FilterParams>;
    infiniteScrolling?: Partial<InfiniteScrollingParams>;
    modules: (new (instance: ContentList) => ContentListModule)[];
    pushState: boolean;
    scrollTo:
        | boolean
        | {
              el?: Element;
              offset?: number;
              behavior?: ScrollBehavior;
          };
    sorting?: Partial<SortingParams>;
}

const prefix = 'chps-content-list';
const LOADING_CLASS = `${prefix}--loading`;
const ERROR_CLASS = `${prefix}--error`;

const defaultData: ContentListData = {
    cacheRequests: false,
    modules: [],
    pushState: true,
    scrollTo: false,
};

class ContentList extends EventEmitter {
    /**
     * Параметры
     */
    readonly data: ContentListData;

    /**
     * Основной контейнер каталога
     */
    readonly el: HTMLElement;

    /**
     * Элемент списка каталога
     */
    readonly contentEl: HTMLElement | null;

    /**
     * Массив модулей каталога
     */
    modules: Readonly<ContentListModule>[] = [];

    /**
     * Объект текущего запроса
     */
    private _currentFetch: AbortableFetch<any> | null;

    /**
     * Pagination link 'click' delegation
     */
    private readonly _paginationDelegation: any;

    constructor(el: HTMLElement, data: Partial<ContentListData> = {}) {
        super();
        this._onPaginationClick = this._onPaginationClick.bind(this);

        this.data = { ...defaultData, ...data };
        this.el = el;
        this.contentEl = this.el.querySelector<HTMLElement>(`.${prefix}-content`);
        this._currentFetch = null;
        this._paginationDelegation = delegate(this.el, `a.${prefix}-pagination-link`, 'click', this._onPaginationClick);
        this.modules = this.data.modules.map((ModuleClass) => new ModuleClass(this));
    }

    /**
     * Прерывает текущий запрос контента каталога
     */
    abortFetchContent() {
        if (this._currentFetch) {
            this._currentFetch.abort();
            this.data.onFetchAbort?.(this);
            this._emitEvent('cl-fetch-abort');
        }
    }

    /**
     * Очищает память, освобождает ресурсы
     */
    destroy() {
        if (this._currentFetch) {
            this._currentFetch.abort();
            this._currentFetch = null;
        }

        this.removeAllListeners();
        this._paginationDelegation.destroy();

        this.modules.forEach((m) => m.destroy());
        this.modules = [];
    }

    /**
     * Отправляет запрос контента каталога по указанному url
     */
    async fetchContent<T = any>(url: string) {
        this.abortFetchContent();
        this.el.classList.remove(ERROR_CLASS);
        this.el.classList.add(LOADING_CLASS);

        this.data.onFetchStart?.(this);
        this._emitEvent('cl-fetch-start');

        try {
            this._currentFetch = abortableFetch<T>(url, {}, this.data.cacheRequests);
            const response = await (this._currentFetch as AbortableFetch<T>).ready;

            this.data.onFetchSuccess?.(this, response);
            this._emitEvent('cl-fetch-success', response);

            if (this.data.pushState) {
                window.history.pushState(null, '', url);
            }

            return response;
        } catch (err: any) {
            if (err.name !== 'AbortError') {
                this.el.classList.add(ERROR_CLASS);
                this.data.onFetchError?.(this, err);
                this._emitEvent('cl-fetch-error', err);
                throw err;
            }
        } finally {
            this.el.classList.remove(LOADING_CLASS);
            this._currentFetch = null;
            this.data.onFetchComplete?.(this);
            this._emitEvent('cl-fetch-complete');
        }
    }

    /**
     * Генерирует событие
     *
     * @param {String} eventName
     * @param {any} data
     */
    _emitEvent(eventName: string, data?: any) {
        this.emit(eventName, data);
        this.el.dispatchEvent(new CustomEvent(eventName, { detail: data }));
    }

    /**
     * Функция обработки клика на ссылку в пагинации
     */
    private _onPaginationClick(event: any) {
        event.preventDefault();
        const target = event.delegateTarget as HTMLAnchorElement;
        this.fetchContent(target.dataset.endpoint || target.href);

        if (this.data.scrollTo !== false && this.contentEl) {
            const scrollToEl =
                typeof this.data.scrollTo === 'object' && this.data.scrollTo.el
                    ? this.data.scrollTo.el
                    : this.contentEl;
            const offset =
                typeof this.data.scrollTo === 'object' && typeof this.data.scrollTo.offset === 'number'
                    ? this.data.scrollTo.offset
                    : 0;
            const behavior =
                typeof this.data.scrollTo === 'object' && typeof this.data.scrollTo.behavior === 'string'
                    ? this.data.scrollTo.behavior
                    : 'smooth';

            window.scrollTo({ top: getOffsetTop(scrollToEl) + offset, behavior });
        }
    }
}

export { ContentList };

export default ContentList;
