import { Collapse } from '@/custom-elements/Collapse';

function init() {
    Array.from(document.querySelectorAll('app-tabs.js-faq-tabs')).forEach((el) => {
        const activeElWrapper = el.querySelector<HTMLElement>('.js-faq-tabs-active-el-wrapper');
        const activeEl = activeElWrapper?.querySelector<HTMLElement>('.js-faq-tabs-active-el');
        const togglers = Array.from(el.querySelectorAll<HTMLElement>('[data-tabs-toggler]'));
        const collapses = Array.from(el.querySelectorAll<Collapse>('app-collapse'));

        const setToIndex = (index: number) => {
            if (togglers[index] && activeElWrapper && activeEl) {
                const rect = togglers[index].getBoundingClientRect();
                activeEl.style.transform = `translate3d(${
                    togglers[index].offsetLeft - (matchMedia('(max-width: 1199px)').matches ? 20 : 0)
                }px, 0, 0) scale(${rect.width / activeElWrapper.offsetWidth})`;
            }
        };

        setToIndex(0);

        el.addEventListener('tab-change', (event: any) => {
            const { newValue } = event.detail;
            setToIndex(newValue);
            collapses.forEach((collapseEl) => {
                collapseEl.setUIState();
            });
        });
    });
}

export default { init };
