import Cookies from 'js-cookie';
import delegate from 'delegate';
import createFormSender from './ajax-form-sender';
import createValidator from '../../modules/validator';
import { removeButtonLoading, setButtonLoading } from '../utils/loading-btn';
import {
    clearAntispamInput,
    hideFormMessages,
    showFailureMessage,
    showFormMessages,
    showSuccessMessage,
} from './ajax-forms';

const BTN_ACTIVE_CLASS = 'is-notify';
const NOTIFIES_COOKIE_NAME = 'NOTIFIES';

function addUniqueItemToArray<T = unknown>(arr: T[], value: T) {
    return [...new Set([...arr, value])];
}

function getNotifies(): (string | number)[] {
    const cookie = Cookies.get(NOTIFIES_COOKIE_NAME);
    return JSON.parse(cookie || '[]');
}

function addToNotifies(productId: string | number) {
    const notifies = addUniqueItemToArray(getNotifies(), productId);
    Cookies.set(NOTIFIES_COOKIE_NAME, JSON.stringify(notifies), { expires: 9999 });
    return notifies;
}

function removeFromNotifies(productId: string | number) {
    const notifies = getNotifies().filter((id) => id !== productId);
    Cookies.set(NOTIFIES_COOKIE_NAME, JSON.stringify(notifies), { expires: 9999 });
    return notifies;
}

async function init() {
    const notifyActionInput = document.querySelector<HTMLInputElement>('.js-notify-action');
    const setActualNotifiesBtnState = () => {
        const notifies = getNotifies().map((id) => String(id));
        const notifyBtn = document.querySelector<HTMLElement>('.js-notify-btn');
        if (notifyBtn) {
            const { productId } = notifyBtn.dataset;

            if (productId && notifies.includes(productId)) {
                notifyBtn.classList.add(BTN_ACTIVE_CLASS);
                if (notifyActionInput) {
                    notifyActionInput.value = 'delete';
                }
            } else {
                if (notifyActionInput) {
                    notifyActionInput.value = 'add';
                }
            }
        }
    };

    setActualNotifiesBtnState();

    const form = document.querySelector<HTMLFormElement>('.js-notify-form');
    const triggerButton = document.querySelector('.js-open-notify-form');
    if (form && triggerButton) {
        const productId = form.dataset.productId;
        if (productId) {
            let isSubmitting = false;
            let hideTimeout: NodeJS.Timeout;
            const validator = createValidator(form, {
                scrollToInvalidInputOptions: {
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                },
            });

            const shouldClearInputs = !form.classList.contains('do-not-clear');
            const sender = createFormSender(form, {
                shouldClearInputs,
                headers: {
                    ['X-Requested-With']: 'XMLHttpRequest',
                },
                onBeforeSend: () => {
                    clearAntispamInput(form);
                },
                onSuccess: ({ status, message, error }) => {
                    if (status === 'success') {
                        if (triggerButton.classList.contains(BTN_ACTIVE_CLASS)) {
                            removeFromNotifies(productId);
                            triggerButton.classList.remove(BTN_ACTIVE_CLASS);
                            if (notifyActionInput) {
                                notifyActionInput.value = 'add';
                            }
                        } else {
                            addToNotifies(productId);
                            triggerButton.classList.add(BTN_ACTIVE_CLASS);
                            if (notifyActionInput) {
                                notifyActionInput.value = 'delete';
                            }
                        }
                        showSuccessMessage(form, message);
                    } else {
                        showFailureMessage(form, message);
                    }
                    if (error) {
                        showFailureMessage(form, error);
                    }
                },
                onError: () => {
                    showFailureMessage(form, 'Something went wrong');
                },
                onComplete: () => {
                    clearTimeout(hideTimeout);
                    showFormMessages(form);
                    hideTimeout = setTimeout(() => hideFormMessages(form), 5000);
                },
            });

            function submitFn(event: Event) {
                if (isSubmitting) return;
                event.preventDefault();

                const isFormValid = validator.validate();
                const submitBtn = form?.querySelector<HTMLButtonElement>('button[type="submit"]');

                if (isFormValid) {
                    isSubmitting = true;

                    if (submitBtn) {
                        setButtonLoading(submitBtn);
                    }

                    sender.send().finally(() => {
                        isSubmitting = false;

                        if (submitBtn) {
                            removeButtonLoading(submitBtn);
                        }
                    });
                }
            }

            function onFocus(this: HTMLInputElement) {
                const inputGroup = this.closest<HTMLElement>('.input-group');

                if (inputGroup) {
                    validator.clearInput(inputGroup);
                }
            }

            validator.inputGroups.forEach((inputGroup) => {
                const input = inputGroup.querySelector<HTMLInputElement>(
                    'input[name]:not([type="submit"]):not([type="reset"]):not([type="hidden"]), select[name]:not([type="submit"]):not([type="reset"]):not([type="hidden"]), textarea[name]:not([type="submit"]):not([type="reset"]):not([type="hidden"])',
                );

                input?.addEventListener('focus', onFocus);
            });
            form.addEventListener('submit', submitFn);
            delegate(form, '.js-remove-message', 'click', () => {
                clearTimeout(hideTimeout);
                hideFormMessages(form);
            });
        }
    }
}

export default { init };
