import Cookies from 'js-cookie';
import delegate from 'delegate';
import axios from 'axios';
import { BaseResponse } from '@/types';

const BTN_ACTIVE_CLASS = 'is-favourite';
const FAVOURITES_COOKIE_NAME = 'FAVOURITES';

function addUniqueItemToArray<T = unknown>(arr: T[], value: T) {
    return [...new Set([...arr, value])];
}

function getFavourites(): string[] {
    const cookie = Cookies.get(FAVOURITES_COOKIE_NAME);
    return JSON.parse(cookie || '[]');
}

function addToFavourites(productId: string | number) {
    const favourites = addUniqueItemToArray(getFavourites(), String(productId));
    Cookies.set(FAVOURITES_COOKIE_NAME, JSON.stringify(favourites), { expires: 9999 });
    const favouritesList = document.querySelector<HTMLElement>('.js-favourites-list');
    const favouritesNoneEl = document.querySelector<HTMLElement>('.js-favourites-none');

    // TODO: add card html to favs
    const template = `
        <li class="favourites-list__item js-favourites-list-item" data-product-id="{{ item.id }}">
            <a href="${ROOT_PATH}catalog/" class="product-card">
                <div class="responsive product-card-img-wrapper">
                    {% if item.labels %}
                    <div class="product-card-labels">
                        {% if item.labels.new %}
                        <div class="product-card-label product-card-label--new">New</div>
                        {% endif %}
                        {% if item.labels.valentine %}
                        <div class="product-card-label product-card-label--blue">Valentines day</div>
                        {% endif %}
                    </div>
                    {% endif %} {% if item.hover_img %}
                    <img
                        src="{{ item.hover_img }}"
                        loading="lazy"
                        decoding="async"
                        class="responsive__item product-card-img product-card-img--hover"
                        alt=""
                    />
                    {% endif %}
                    <img
                        src="{{ item.img }}"
                        loading="lazy"
                        decoding="async"
                        class="responsive__item product-card-img product-card-img--default"
                        alt=""
                    />
                    <button
                        class="app-icon product-card__fav-btn js-product-favourites-add"
                        aria-label="Add to favourites"
                        data-product-id="{{ item.id }}"
                        data-endpoint="/static/php/favourites.php"
                    >
                        {% include "partials/svg/heart.svg" %}
                    </button>
                </div>
                <div class="product-card-content">
                    <div class="product-card__name">{{ item.brand_name }}</div>
                    <div class="product-card__type">{{ item.type }}</div>
                    <div class="product-card__price">{{ item.price }}</div>
                </div>
                <div class="product-card__add-btn-wrapper js-product-cart-wrapper">
                    <button
                        class="btn btn-dark product-card__add-btn js-product-basket-add"
                        data-product-id="{{ item.id }}"
                        data-endpoint="/static/php/basket.php"
                        data-cart-url="{{ APP.ROOT_PATH }}cart/"
                    >
                        <span class="btn__text">Add to cart</span>
                    </button>
                </div>
            </a>
        </li>
    `;

    if (favouritesNoneEl) {
        favouritesNoneEl.hidden = favourites.length > 0;
    }

    if (favouritesList) {
        favouritesList.hidden = favourites.length === 0;
    }

    return favourites;
}

function removeFromFavourites(...productIds: (string | number)[]) {
    const favourites = getFavourites().filter((id) => !productIds.includes(String(id)));
    Cookies.set(FAVOURITES_COOKIE_NAME, JSON.stringify(favourites), { expires: 9999 });
    const favouritesList = document.querySelector<HTMLElement>('.js-favourites-list');
    const favouritesNoneEl = document.querySelector<HTMLElement>('.js-favourites-none');

    productIds.forEach((id) => {
        Array.from(document.querySelectorAll<HTMLElement>('.js-favourites-list-item'))
            .find((el) => el.dataset.productId === id)
            ?.remove();
    });

    if (favouritesNoneEl) {
        favouritesNoneEl.hidden = favourites.length > 0;
    }

    if (favouritesList) {
        favouritesList.hidden = favourites.length === 0;
    }

    return favourites;
}

export const setActualFavouritesBtnState = () => {
    const favourites = getFavourites();
    Array.from(document.querySelectorAll<HTMLElement>('.js-product-favourites-add')).forEach((el) => {
        const { productId } = el.dataset;

        if (productId && favourites.includes(productId)) {
            el.classList.add(BTN_ACTIVE_CLASS);
        } else {
            el.classList.remove(BTN_ACTIVE_CLASS);
        }
    });
};

export const setActualFavouritesCount = () => {
    const counterElements = Array.from(document.querySelectorAll('.js-favourites-counter'));
    const currentFavourites = getFavourites();

    counterElements.forEach((el) => {
        el.textContent =
            currentFavourites.length > 0 ? (currentFavourites.length > 9 ? '9+' : `${currentFavourites.length}`) : '';
    });
};

async function init() {
    setActualFavouritesCount();
    setActualFavouritesBtnState();

    delegate(document, '.js-product-favourites-add', 'click', async (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const target = event.delegateTarget as HTMLButtonElement;
        const { productId, endpoint } = target.dataset;

        if (productId && endpoint) {
            target.disabled = true;

            if (target.classList.contains(BTN_ACTIVE_CLASS)) {
                try {
                    const response = await axios.post<
                        BaseResponse<{
                            s: string;
                        }>
                    >(endpoint, {
                        action: 'delete',
                        productIds: [parseInt(productId)],
                    });

                    if (response.data.status === 'success') {
                        removeFromFavourites(productId);
                        setActualFavouritesCount();
                        setActualFavouritesBtnState();
                    }
                } finally {
                    target.disabled = false;
                }
            } else {
                try {
                    const response = await axios.post<
                        BaseResponse<{
                            s: string;
                        }>
                    >(endpoint, {
                        action: 'add',
                        productIds: [parseInt(productId)],
                    });

                    if (response.data.status === 'success') {
                        addToFavourites(productId);
                        setActualFavouritesCount();
                        setActualFavouritesBtnState();
                    }
                } finally {
                    target.disabled = false;
                }
            }
        }
    });

    delegate(document, '.js-product-favourites-remove-all', 'click', async (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const target = event.delegateTarget as HTMLButtonElement;
        const { endpoint } = target.dataset;
        const productIds = getFavourites();

        if (endpoint && productIds.length > 0) {
            target.disabled = true;

            try {
                const response = await axios.post<
                    BaseResponse<{
                        s: string;
                    }>
                >(endpoint, {
                    action: 'delete',
                    productIds: productIds.map((id) => Number(id)),
                });

                if (response.data.status === 'success') {
                    removeFromFavourites(...productIds);
                    target.classList.remove(BTN_ACTIVE_CLASS);
                    setActualFavouritesCount();
                    setActualFavouritesBtnState();
                }
            } finally {
                target.disabled = false;
            }
        }
    });
}

export default { init };
