import debounce from 'lodash.debounce';
import axios from 'axios';
import { pushNotification } from '../components/notifications';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
import { BaseResponse } from '../types';
import { setActualFavouritesBtnState } from './favourites';

function init() {
    const searchPopupEl = document.querySelector<LitPopupElement>('app-lit-popup[data-lit-popup="search"]');
    const openers = Array.from(document.querySelectorAll<HTMLElement>('[data-lit-popup-open="search"]'));
    const searchPopupForm = searchPopupEl?.querySelector<HTMLFormElement>('form.js-search-form');
    const searchPopupInput = searchPopupForm?.querySelector<HTMLInputElement>('input.js-search-popup-input');
    const searchPopupResetBtn = searchPopupForm?.querySelector<HTMLInputElement>('.js-search-form-reset');
    const queryItems = Array.from((searchPopupEl || document).querySelectorAll<HTMLElement>('.js-query-item'));

    if (searchPopupEl) {
        searchPopupEl.addEventListener('open', () => {
            document.documentElement.classList.add('search-popup-opened');

            setTimeout(() => {
                openers.forEach((el) => {
                    el.removeAttribute('data-lit-popup-open');
                    el.setAttribute('data-lit-popup-close', 'search');
                });
            }, 1);

            setTimeout(() => {
                searchPopupInput?.focus();
            }, 50);
        });

        searchPopupEl.addEventListener('close', () => {
            document.documentElement.classList.remove('search-popup-opened');

            setTimeout(() => {
                openers.forEach((el) => {
                    el.removeAttribute('data-lit-popup-close');
                    el.setAttribute('data-lit-popup-open', 'search');
                });
            }, 1);
        });
    }

    function onQueryItemClick(this: HTMLElement) {
        const { value } = this.dataset;

        if (value && searchPopupInput) {
            searchPopupInput.value = value.trim();
            searchPopupInput.dispatchEvent(new Event('input'));

            // if (value.trim().length >= MIN_SYMBOLS) {
            fetchSearchResults(value.trim());
            // }
        }
    }

    queryItems.forEach((el) => {
        el.addEventListener('click', onQueryItemClick);
    });

    searchPopupForm?.addEventListener('submit', (event) => {
        if (searchPopupInput && searchPopupInput.value.trim().length === 0) {
            event.preventDefault();
        }
    });

    searchPopupInput?.addEventListener('input', () => {
        if (searchPopupForm) {
            if (searchPopupInput.value.length > 0) {
                searchPopupForm.classList.add('is-filled');
            } else {
                searchPopupForm.classList.remove('is-filled');
            }
        }
    });

    searchPopupResetBtn?.addEventListener('click', () => {
        if (searchPopupInput) {
            searchPopupInput.value = '';
            searchPopupInput.dispatchEvent(new Event('input'));
            searchPopupInput.focus();
        }
    });

    const catalogEl = document.querySelector<HTMLElement>('.js-search-popup-content');
    const loaderContainer = document.querySelector<HTMLElement>('.js-search-popup-content-loader-container');

    // Search on input change
    const endpoint = searchPopupForm?.dataset.searchEndpoint;
    const MIN_SYMBOLS = 3;
    let abortController: AbortController | null;

    const fetchSearchResults = async (query: string) => {
        if (!endpoint) {
            throw new Error('No endpoint provided');
        }

        if (!searchPopupInput || !searchPopupInput.name) {
            throw new Error('No input name provided');
        }

        const DEFAULT_ERROR_MESSAGE = 'Something went wrong trying to fetch products';
        loaderContainer?.classList.add('is-visible');
        abortController = new AbortController();

        try {
            const { data } = await axios.get<
                BaseResponse<{
                    contentHtml: string;
                }>
            >(endpoint, {
                params: {
                    [searchPopupInput.name]: query,
                },
                signal: abortController.signal,
            });

            if (data.status === 'error') {
                pushNotification(data.message ?? DEFAULT_ERROR_MESSAGE, true);
            } else {
                if (data.data) {
                    if (data.data.contentHtml && catalogEl) {
                        catalogEl.innerHTML = data.data.contentHtml;
                        setActualFavouritesBtnState();
                        // catalogEl.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                    }
                }
            }
        } catch (err) {
            if (err instanceof Error && err.name !== 'CanceledError') {
                pushNotification(err instanceof Error ? err.message : DEFAULT_ERROR_MESSAGE, true);
            }
        } finally {
            abortController = null;
            loaderContainer?.classList.remove('is-visible');
        }
    };

    const debouncedFetchSearchResults = debounce(fetchSearchResults, 500);

    const onInput = (event: any) => {
        if (abortController) {
            abortController.abort();
            abortController = null;
        }

        // if (event.target.value.trim().length >= MIN_SYMBOLS) {
        debouncedFetchSearchResults(event.target.value.trim());
        // }
    };

    searchPopupInput?.addEventListener('input', onInput);
}

export default { init };
