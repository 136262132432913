/**
 * Возвращает расстояние (в пикселях) от верхнего края документа от элемента
 *
 * @param {Element} el
 * @param {Number} windowScrollY
 * @param {Number} heightOffset
 * @returns {Number}
 */
export function getOffsetTop(el: Element, windowScrollY = window.scrollY, heightOffset = 0): number {
    return el.getBoundingClientRect().top + windowScrollY - heightOffset;
}
