function onOpenBtnClick(this: HTMLElement) {
    if (matchMedia('(max-width: 1024px)').matches) {
        document.documentElement.classList.add('no-scroll');
    }

    this.closest('.js-order')?.querySelector('.js-order-content-wrapper')?.classList.add('is-visible');
}

function onCloseBtnClick(this: HTMLElement) {
    document.documentElement.classList.remove('no-scroll');
    this.closest('.js-order')?.querySelector('.js-order-content-wrapper')?.classList.remove('is-visible');
}

function init() {
    Array.from(document.querySelectorAll('.js-order-toggler')).forEach((el) => {
        el.addEventListener('click', onOpenBtnClick);
    });

    Array.from(document.querySelectorAll('.js-order-close')).forEach((el) => {
        el.addEventListener('click', onCloseBtnClick);
    });
}

export default { init };
