import createFormSender, { AjaxFormSender } from './ajax-form-sender';
import createValidator from '../../modules/validator';
import {
    clearAntispamInput,
    hideFormMessages,
    showFailureMessage,
    showFormMessages,
    showSuccessMessage,
} from './ajax-forms';

async function init() {
    const form = document.querySelector<HTMLFormElement>('.js-promocode-form');

    const promocodeInput = document.querySelector<HTMLInputElement>('.js-promocode-input');
    const promocodeSubmit = document.querySelector<HTMLButtonElement>('.js-promocode-submit');

    if (promocodeInput && promocodeSubmit) {
        promocodeInput.addEventListener('input', (event: Event) => {
            const eventTarget = event.target as HTMLInputElement;
            if (eventTarget.value.trim().length > 0) {
                promocodeSubmit.classList.add('is-visible');
            } else {
                promocodeSubmit.classList.remove('is-visible');
            }
        });
    }

    const summaryTotal = document.querySelector<HTMLElement>('.js-summary-total');
    const summaryList = document.querySelector<HTMLElement>('.js-summary-list');

    if (form) {
        let isSubmitting = false;
        let hideTimeout: NodeJS.Timeout;
        const validator = createValidator(form, {
            scrollToInvalidInputOptions: {
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            },
        });

        const sender: AjaxFormSender = createFormSender(form, {
            shouldClearInputs: false,
            headers: {
                ['X-Requested-With']: 'XMLHttpRequest',
            },
            onBeforeSend: () => {
                clearAntispamInput(form);
            },
            onSuccess: ({ status, message, data }) => {
                if (status === 'success') {
                    showSuccessMessage(form, message);
                } else {
                    showFailureMessage(form, message);
                }

                if (data.new_total && summaryTotal) {
                    summaryTotal.innerHTML = data.new_total;
                }

                if (data.summary && summaryList) {
                    summaryList.innerHTML = data.summary;
                }
            },
            onError: (err) => {
                showFailureMessage(form, err.message || 'Something went wrong');
            },
            onComplete: () => {
                clearTimeout(hideTimeout);
                showFormMessages(form);
                hideTimeout = setTimeout(() => hideFormMessages(form), 5000);
            },
        });

        function submitFn(event: Event) {
            if (isSubmitting) return;
            event.preventDefault();

            const isFormValid = validator.validate();
            const submitBtn = form?.querySelector<HTMLButtonElement>('button[type="submit"]');

            if (isFormValid) {
                isSubmitting = true;

                if (submitBtn) {
                    submitBtn.classList.add('is-loading');
                }

                sender.send().finally(() => {
                    isSubmitting = false;

                    if (submitBtn) {
                        submitBtn.classList.remove('is-loading');
                    }
                });
            }
        }

        function onFocus(this: HTMLInputElement) {
            const inputGroup = this.closest<HTMLElement>('.input-group');

            if (inputGroup) {
                validator.clearInput(inputGroup);
            }
        }

        validator.inputGroups.forEach((inputGroup) => {
            const input = inputGroup.querySelector<HTMLInputElement>(
                'input[name]:not([type="submit"]):not([type="reset"]):not([type="hidden"]), select[name]:not([type="submit"]):not([type="reset"]):not([type="hidden"]), textarea[name]:not([type="submit"]):not([type="reset"]):not([type="hidden"])',
            );

            input?.addEventListener('focus', onFocus);
        });
        form.addEventListener('submit', submitFn);
    }
}

export default { init };
