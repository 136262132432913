/**
 * Модуль: Фильтрация
 */

import serialize from 'form-serialize';
import debounce from 'lodash.debounce';
import delegate from 'delegate';
import type { ContentList } from '..';
import type { ContentListModule, FilterParams } from '../types';

const defaultData: FilterParams = {
    filterOnInputChange: false,
    inputDebounceTime: 500,
};

class ContentListFilter implements ContentListModule {
    /**
     * Экземпляр класса ContentList
     */
    readonly contentListInstance: ContentList;

    /**
     * Параметры
     */
    readonly data: FilterParams;

    /**
     * Элемент контейнера фильтра
     */
    readonly el: HTMLElement;

    /**
     * Debounced 'fetchContent'
     */
    private readonly _debouncedOnFilterChange: ReturnType<typeof debounce>;

    /**
     * Input 'change' delegation
     */
    private readonly _inputChangeDelegation: any;

    /**
     * Input 'input' delegation
     */
    private readonly _inputInputDelegation: any;

    /**
     * Новый URL с GET-параметрами, который будет применен при успешной фильтрации
     * (если с бэкенда не пришел URL)
     */
    private _nextUrl: string;

    /**
     * Form 'submit' delegation
     */
    private readonly _submitDelegation: any;

    /**
     * Reset button 'click' delegation
     */
    private readonly _resetDelegation: any;

    constructor(contentListInstance: ContentList) {
        this._onFilterChange = this._onFilterChange.bind(this);

        this.contentListInstance = contentListInstance;
        this.data = { ...defaultData, ...(contentListInstance.data.filter || {}) };

        if (!this.data.el) {
            throw new Error('Filter element not found');
        }

        this._debouncedOnFilterChange = debounce(this._onFilterChange, this.data.inputDebounceTime);
        this.el = this.data.el;
        this._nextUrl = '';

        this._submitDelegation = delegate(this.el, 'form', 'submit', (event: any) => {
            event.preventDefault();
            this._onFilterChange();
        });
        this._resetDelegation = delegate(this.el, '[type="reset"], .js-reset-filters', 'click', (event: Event) =>
            this.resetFilter(event),
        );
        this._inputChangeDelegation = delegate(this.el, 'input, select', 'change', (event: any) => {
            const target = event.delegateTarget as HTMLInputElement;
            const { name } = target.dataset;

            /**
             * Логика динамического чека/анчека чекбоксов с одинаковым именем:
             *
             * (1) Когда выбран чекбокс "Все" - остальные должны быть анчекнуты.
             *
             * (2) Когда выбрано что-то, кроме "Все" - нужно анчекнуть "Все".
             *
             * (3) Когда анчекнуты все чекбоксы - нужно чекнуть "Все".
             */
            if (target.type === 'checkbox') {
                const checkboxesToVerify = Array.from(
                    name
                        ? this.el.querySelectorAll<HTMLInputElement>(`input[type="checkbox"][data-name="${name}"]`)
                        : this.el.querySelectorAll<HTMLInputElement>(`input[type="checkbox"][name="${target.name}"]`),
                );

                const emptyValueCheckbox = checkboxesToVerify.find((checkbox) => !checkbox.value.trim());
                const otherCheckboxes = checkboxesToVerify.filter((checkbox) => checkbox !== emptyValueCheckbox);

                if (emptyValueCheckbox) {
                    if (checkboxesToVerify.every((checkbox) => !checkbox.checked)) {
                        // (3)
                        emptyValueCheckbox.checked = true;
                    } else {
                        if (target === emptyValueCheckbox) {
                            if (target.checked) {
                                // (1)
                                otherCheckboxes.forEach((checkbox) => {
                                    checkbox.checked = false;
                                });
                            }
                        } else if (otherCheckboxes.some((checkbox) => checkbox.checked)) {
                            // (2)
                            emptyValueCheckbox.checked = false;
                        }
                    }
                }
            }

            if (this.data.filterOnInputChange) {
                this._debouncedOnFilterChange();
            }
        });
    }

    /**
     * Дестрой
     */
    destroy() {
        this._inputChangeDelegation.destroy();
        this._submitDelegation.destroy();
        this._resetDelegation.destroy();
    }

    /**
     * Скидывает значения инпутов фильтра в начальное состояние
     */
    resetFilter(event?: Event) {
        if (event) {
            event.preventDefault();
        }
        const inputs = Array.from(
            this.el.querySelectorAll<HTMLInputElement>(
                'input:not([type="submit"]):not([type="reset"]):not([type="hidden"])',
            ),
        );

        inputs.forEach((input) => {
            if (input.type === 'checkbox') {
                input.checked = !input.value;
            } else if (input.type === 'radio') {
                // TODO: check default?
                input.checked = false;
            } else if (input.type !== 'hidden') {
                input.value = '';
            }
        });

        this._onFilterChange();
    }

    /**
     * Функция изменения параметров фильтра
     */
    private _onFilterChange() {
        const target = this.el.querySelector('form');

        if (target) {
            const formData = serialize(target);
            const formDataObject = this.getFormDataObject();
            this.el.dispatchEvent(new CustomEvent('filter-change', { detail: formDataObject }));
            const sign = /\?/.test(target.action) ? '&' : '?';
            const url = target.action + sign + formData;
            this._nextUrl = url;
            this.contentListInstance.fetchContent(url);
        }
    }

    getFormDataObject() {
        const target = this.el.querySelector('form');
        return target ? serialize(target, { hash: true }) : null;
    }
}

export { ContentListFilter };

export default ContentListFilter;
