import { LitPopupElement } from '@/custom-elements/LitPopupElement/LitPopupElement';

function init() {
    document.addEventListener('click', (event: Event) => {
        const target = event.target as HTMLElement;

        if (!target.closest('.js-dialog-el')) {
            const popup = target.closest<LitPopupElement>('app-lit-popup[data-lit-popup-preset="slide"]');
            popup?.close();
        }
    });
}

export default { init };
