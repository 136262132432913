import { fetchSwiper } from '../dynamic-modules';

const getSlidesPerView = () => {
    switch (true) {
        case matchMedia('(max-width: 480px)').matches:
            return 2;
        default:
            return 5;
    }
};

async function init() {
    const elements = Array.from(document.querySelectorAll<HTMLElement>('.js-products-slider'));

    if (elements.length > 0) {
        const { Swiper } = await fetchSwiper();

        elements.forEach((slider) => {
            const slides = Array.from(slider.querySelectorAll('.swiper-slide'));

            if (slides.length > getSlidesPerView()) {
                const swiper = new Swiper(slider, {
                    slidesPerView: 1.9,
                    spaceBetween: 0,
                    grabCursor: true,
                    speed: 300,
                    longSwipesMs: 100,
                    longSwipesRatio: 0.1,
                    cssMode: true,
                    on: {
                        afterInit: () => {
                            const navContainer = slider.parentElement?.querySelector<HTMLElement>(
                                '.js-products-slider-nav-container',
                            );

                            if (navContainer) {
                                const prevBtn = document.createElement('button');
                                prevBtn.className = 'round-btn products-slider-nav-btn products-slider-nav-btn--prev';
                                prevBtn.innerHTML = `<svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 1L7.5 6L1.5 11" stroke="#6D6C70" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                `;
                                prevBtn.addEventListener('click', () => swiper.slidePrev());

                                const nextBtn = document.createElement('button');
                                nextBtn.className = 'round-btn products-slider-nav-btn products-slider-nav-btn--next';
                                nextBtn.innerHTML = `<svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 1L7.5 6L1.5 11" stroke="#6D6C70" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                `;
                                nextBtn.addEventListener('click', () => swiper.slideNext());

                                navContainer.appendChild(prevBtn);
                                navContainer.appendChild(nextBtn);
                            }
                        },
                    },
                    breakpoints: {
                        1200: {
                            cssMode: false,
                            slidesPerView: 5,
                            spaceBetween: 20,
                        },
                    },
                });
            }
        });
    }
}

export default { init };
