import SlimSelect from 'slim-select';

const map = new WeakMap<HTMLSelectElement, SlimSelect>();

function init(container: HTMLElement | Document = document) {
    const selectEls = Array.from(container.querySelectorAll<HTMLSelectElement>('.js-select'));

    selectEls.forEach((element) => {
        const instance = new SlimSelect({
            select: element,
            settings: {
                showSearch: false,
            },
        });

        map.set(element, instance);
    });
}

function destroy(container: HTMLElement | Document = document) {
    const selectEls = Array.from(container.querySelectorAll<HTMLSelectElement>('.js-select'));

    selectEls.forEach((element) => {
        const instance = map.get(element);
        if (instance) {
            instance.destroy();
        }
        map.delete(element);
    });
}

const _module = { init, destroy };

export default _module;
