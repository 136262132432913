import { fetchSwiper } from '../dynamic-modules';

async function init() {
    const mainSliderEl = document.querySelector<HTMLElement>('.js-detail-slider');
    const thumbsSliderEl = document.querySelector<HTMLElement>('.js-detail-slider-thumbs');
    const sliderWrapper = document.querySelector<HTMLElement>('.js-detail-slider-wrapper');

    let isMobile = window.matchMedia('screen and (max-width: 767px)').matches;

    window.addEventListener('resize', () => {
        isMobile = window.matchMedia('screen and (max-width: 767px)').matches;
    });

    if (mainSliderEl && thumbsSliderEl) {
        const slides = Array.from(mainSliderEl.querySelectorAll('.swiper-slide'));
        const { Swiper, Thumbs, EffectFade } = await fetchSwiper();

        if (slides.length > 1) {
            const thumbsSlider = new Swiper(thumbsSliderEl, {
                modules: [Thumbs],
                spaceBetween: isMobile ? 8 : 20,
                slidesPerView: 'auto',
                watchSlidesProgress: true,
                direction: isMobile ? 'horizontal' : 'vertical',
                allowTouchMove: false,
                // https://qna.habr.com/q/1152372#answer_2167314 фикс кликов по свайперу
                on: {
                    touchEnd: function (s, e) {
                        const range = 5;
                        const diff = (s.touches.diff = s.isHorizontal()
                            ? s.touches.currentX - s.touches.startX
                            : s.touches.currentY - s.touches.startY);
                        if (diff < range || diff > -range) {
                            s.allowClick = true;
                        }
                    },
                },
            });

            const mainSlider = new Swiper(mainSliderEl, {
                modules: [Thumbs, EffectFade],
                spaceBetween: 30,
                slidesPerView: 1,
                allowTouchMove: isMobile,
                effect: isMobile ? undefined : 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                thumbs: {
                    swiper: thumbsSlider,
                },
                on: {
                    slideChange: (instance) => {
                        const currentSlide = instance.slides[instance.activeIndex];
                        const prevSlide = instance.slides[instance.previousIndex];

                        if (currentSlide && currentSlide.classList.contains('js-slide-with-video')) {
                            const video = currentSlide.querySelector<HTMLVideoElement>('.js-detail-video-in-slider');
                            if (video) {
                                video.play();
                            }
                        }

                        if (prevSlide && prevSlide.classList.contains('js-slide-with-video')) {
                            const video = prevSlide.querySelector<HTMLVideoElement>('.js-detail-video-in-slider');

                            if (video) {
                                video.pause();
                            }
                        }
                    },
                },
            });
        }
    }

    if (sliderWrapper) {
        const videos = Array.from(sliderWrapper.querySelectorAll<HTMLVideoElement>('.js-detail-video-in-slider'));

        if (videos.length > 0) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        videos.forEach((video) => {
                            if (video.closest('.swiper-slide-active')) {
                                video.play();
                            }
                        });
                    } else {
                        videos.forEach((video) => video.pause());
                    }
                });
            });
            observer.observe(sliderWrapper);
        }
    }
}

export default { init };
