import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { viewport } from '../utils/viewport';

async function init() {
    const elements = Array.from(document.querySelectorAll<HTMLElement>('.js-about-slider'));

    if (elements.length > 0) {
        const supportsCSSViewTimeline =
            typeof window !== 'undefined' ? CSS.supports('animation-timeline', 'view()') : true;

        elements.forEach((slider) => {
            const innerEl = slider.querySelector<HTMLElement>('.js-about-slider-wrapper');

            if (supportsCSSViewTimeline) {
                if (innerEl) {
                    innerEl.style.setProperty('--parallax-x-from', `${0}px`);
                    innerEl.style.setProperty(
                        '--parallax-x-to',
                        `${-gsap.utils.clamp(0, viewport.width, slider.scrollWidth - slider.offsetWidth)}px`,
                    );
                    innerEl.style.animation = 'parallax linear';
                    innerEl.style.animationFillMode = 'both';
                    (innerEl.style as any).animationTimeline = 'view()';
                    (innerEl.style as any).animationRange = 'cover 0% cover 100%';

                    window.addEventListener('resize', () => {
                        innerEl.style.setProperty(
                            '--parallax-x-to',
                            `${-gsap.utils.clamp(0, viewport.width, slider.scrollWidth - slider.offsetWidth)}px`,
                        );
                    });
                }
            } else {
                gsap.registerPlugin(ScrollTrigger);

                if (innerEl) {
                    innerEl.style.willChange = 'transform';
                    gsap.to(innerEl, {
                        ease: 'none',
                        x: () => -gsap.utils.clamp(0, viewport.width, slider.scrollWidth - slider.offsetWidth),
                        scrollTrigger: {
                            trigger: slider,
                            start: 'top bottom',
                            end: 'bottom top',
                            scrub: true,
                            invalidateOnRefresh: true,
                        },
                    });
                }
            }
        });
    }
}

export default { init };
