// import LRU from 'lru-cache';

// const cache = new LRU<string, any>({ max: 30 });

export type AbortableFetch<T = any> = {
    abort: () => void;
    ready: Promise<T>;
};

export function abortableFetch<T = any>(request: Request | string, opts: RequestInit = {}, useCache = false) {
    const url = typeof request === 'string' ? request : request.url;
    const controller = new AbortController();

    const fetchPromise = () => {
        const fr: Promise<T> = fetch(request, {
            ...opts,
            headers: {
                ...opts.headers,
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
            },
            signal: controller.signal,
        }).then((res) => res.json());

        // if (useCache) {
        //     fr.then((response) => {
        //         cache.set(url, response);
        //     });
        // }

        return fr;
    };

    const createFetchObj = (cachedResponse?: T): AbortableFetch<T> => ({
        abort: () => controller.abort(),
        ready: cachedResponse ? Promise.resolve(cachedResponse) : fetchPromise(),
    });

    // if (useCache) {
    //     const cachedResponse = cache.get(url) as T | undefined;
    //     return createFetchObj(cachedResponse);
    // }

    return createFetchObj();
}
