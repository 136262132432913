import type { InputMask } from 'imask';
import { fetchIMask } from '../dynamic-modules';

const map = new WeakMap<HTMLInputElement, InputMask>();

export async function initPhoneMask(input: HTMLInputElement, dialCode = '7') {
    const IMask = await fetchIMask();
    const imask = IMask(input, {
        mask: `+${'0'.repeat(14)}`,
        prepare: (appended: string, masked: { value: string }) => {
            if (appended === '8' && masked.value === '') {
                return dialCode;
            }
            if (appended.length > 1 && appended.substring(0, 1) === '8') {
                return `7${appended.slice(1)}`;
            }
            return appended;
        },
    });
    map.set(input, imask);
}

export async function initOneTimeCodeMask(input: HTMLInputElement) {
    const IMask = await fetchIMask();
    const imask = IMask(input, {
        mask: `0000`,
    });
    map.set(input, imask);
}

export async function iniDateMask(input: HTMLInputElement) {
    const IMask = await fetchIMask();
    const imask = IMask(input, {
        mask: `00.00.0000`,
    });
    map.set(input, imask);
}

function init(container: Element | Document = document) {
    const phoneElements = Array.from(container.querySelectorAll<HTMLInputElement>('input[data-mask="phone"]'));

    phoneElements.forEach((el) => {
        initPhoneMask(el);
    });

    Array.from(document.querySelectorAll<HTMLInputElement>('input[data-mask="one-time-code"]')).forEach(
        initOneTimeCodeMask,
    );

    Array.from(document.querySelectorAll<HTMLInputElement>('input[data-mask="date"]')).forEach(iniDateMask);
}

function getInstanceByElement(el?: HTMLInputElement | null) {
    return el ? map.get(el) : null;
}

const _module = { init, getInstanceByElement };

export default _module;
