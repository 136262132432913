import createFormSender, { AjaxFormSender } from './ajax-form-sender';
import createValidator from '../../modules/validator';
import {
    clearAntispamInput,
    hideFormMessages,
    showFailureMessage,
    showFormMessages,
    showSuccessMessage,
} from './ajax-forms';

async function init() {
    const form = document.querySelector<HTMLFormElement>('.js-order-form');

    if (form) {
        let isSubmitting = false;
        let hideTimeout: NodeJS.Timeout;
        const validator = createValidator(form, {
            scrollToInvalidInputOptions: {
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            },
        });

        const sender: AjaxFormSender = createFormSender(form, {
            shouldClearInputs: false,
            headers: {
                ['X-Requested-With']: 'XMLHttpRequest',
            },
            onBeforeSend: () => {
                clearAntispamInput(form);
            },
            onSuccess: ({ status, redirect_url }) => {
                if (status === 'success' && redirect_url) {
                    window.open(redirect_url, '_blank');
                }
            },
            onError: (err) => {
                showFailureMessage(form, err.message || 'Something went wrong');
            },
            onComplete: () => {
                clearTimeout(hideTimeout);
                showFormMessages(form);
                hideTimeout = setTimeout(() => hideFormMessages(form), 5000);
            },
        });

        function submitFn(event: Event) {
            if (isSubmitting) return;
            event.preventDefault();

            const isFormValid = validator.validate();
            const submitBtn = form?.querySelector<HTMLButtonElement>('button[type="submit"]');

            if (isFormValid) {
                isSubmitting = true;

                if (submitBtn) {
                    submitBtn.classList.add('is-loading');
                }

                sender.send().finally(() => {
                    isSubmitting = false;

                    if (submitBtn) {
                        submitBtn.classList.remove('is-loading');
                    }
                });
            }
        }

        function onFocus(this: HTMLInputElement) {
            const inputGroup = this.closest<HTMLElement>('.input-group');

            if (inputGroup) {
                validator.clearInput(inputGroup);
            }
        }

        validator.inputGroups.forEach((inputGroup) => {
            const input = inputGroup.querySelector<HTMLInputElement>(
                'input[name]:not([type="submit"]):not([type="reset"]):not([type="hidden"]), select[name]:not([type="submit"]):not([type="reset"]):not([type="hidden"]), textarea[name]:not([type="submit"]):not([type="reset"]):not([type="hidden"])',
            );

            input?.addEventListener('focus', onFocus);
        });
        form.addEventListener('submit', submitFn);
    }
}

export default { init };
