import { BaseResponse } from '@/types';
import axios from 'axios';
import delegate from 'delegate';
import Cookies from 'js-cookie';

const BASKET_COOKIE_NAME = 'BASKET';

function addUniqueItemToArray<T = unknown>(arr: T[], value: T) {
    return [...new Set([...arr, value])];
}

function getBasket(): (string | number)[] {
    const cookie = Cookies.get(BASKET_COOKIE_NAME);
    return JSON.parse(cookie || '[]');
}

function addToBasket(productId: string | number) {
    const basket = addUniqueItemToArray(getBasket(), productId);
    Cookies.set(BASKET_COOKIE_NAME, JSON.stringify(basket), { expires: 9999 });
    return basket;
}

function removeFromBasket(productId: string | number) {
    const basket = getBasket().filter((id) => id !== productId);
    Cookies.set(BASKET_COOKIE_NAME, JSON.stringify(basket), { expires: 9999 });
    return basket;
}

async function init() {
    const cartAction = Array.from(document.querySelectorAll('.js-header-cart-action'));
    const counterElements = Array.from(document.querySelectorAll('.js-cart-counter'));
    const summaryTotal = document.querySelector<HTMLElement>('.js-summary-total');
    const summaryList = document.querySelector<HTMLElement>('.js-summary-list');

    const setActualBasketCount = () => {
        const currentBasket = getBasket();

        counterElements.forEach((el) => {
            el.textContent =
                currentBasket.length > 0 ? (currentBasket.length > 9 ? '9+' : `${currentBasket.length}`) : '';
        });

        if (currentBasket.length > 0) {
            cartAction.forEach((el) => {
                el.classList.add('is-active');
            });
        } else {
            cartAction.forEach((el) => {
                el.classList.remove('is-active');
            });
        }
    };

    setActualBasketCount();

    delegate(document, '.js-product-cart-status', 'click', async (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const target = event.delegateTarget as HTMLButtonElement;
        const { productId, endpoint } = target.dataset;
        const cartItem = target.closest<HTMLElement>('.js-cart-item');

        if (cartItem) {
            const isCanceled = cartItem.classList.contains('cart-item--canceled');

            if (productId && endpoint) {
                cartItem.classList.add('is-loading');

                try {
                    const response = await axios.post<
                        BaseResponse<{
                            s: string;
                            new_total: string;
                            summary: string;
                        }>
                    >(endpoint, {
                        action: isCanceled ? 'add' : 'remove',
                        productIds: [parseInt(productId)],
                    });

                    if (response.data.status === 'success') {
                        if (isCanceled) {
                            addToBasket(productId);
                            cartItem.classList.remove('cart-item--canceled');
                        } else {
                            removeFromBasket(productId);
                            cartItem.classList.add('cart-item--canceled');
                        }

                        if (response.data.data?.new_total && summaryTotal) {
                            summaryTotal.innerHTML = response.data.data?.new_total;
                        }

                        if (response.data.data?.summary && summaryList) {
                            summaryList.innerHTML = response.data.data?.summary;
                        }
                        setActualBasketCount();
                    }
                } finally {
                    cartItem.classList.remove('is-loading');
                }
            }
        }
    });
}

export default { init };
