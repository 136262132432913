import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import delegate from 'delegate';
import { getOffsetTop } from '../utils/dom';

const header = document.querySelector<HTMLElement>('.js-header');

gsap.registerPlugin(ScrollToPlugin);

function init() {
    delegate(document, '[data-scrollto]', 'click', (event: any) => {
        event.preventDefault();
        const target = event.delegateTarget as HTMLElement;

        if (target.dataset.scrollto) {
            const scrollToEl = document.querySelector(target.dataset.scrollto);

            if (scrollToEl) {
                const scrollTo = getOffsetTop(scrollToEl) - (header?.offsetHeight || 0) - 20;
                gsap.to(window, {
                    duration: 1,
                    scrollTo,
                    ease: 'power3.out',
                });
            }
        }
    });
}

const _module = { init };

export default _module;
