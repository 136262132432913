const loaderTemplate = `<span class="loader"></span>`;

export function setButtonLoading(el: HTMLButtonElement) {
    const textEl = el.querySelector('.btn__text') || el;
    const rect = el.getBoundingClientRect();
    el.disabled = true;
    Object.assign(el.style, { width: `${rect.width}px`, height: `${rect.height}px` });
    el.dataset.actualText = el.textContent || '';
    textEl.innerHTML = loaderTemplate;
}

export function removeButtonLoading(el: HTMLButtonElement) {
    const textEl = el.querySelector('.btn__text') || el;
    el.disabled = false;
    Object.assign(el.style, { width: '', height: '' });
    textEl.textContent = el.dataset.actualText || '';
    el.removeAttribute('data-actual-text');
}
