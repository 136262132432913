import { Collapse } from '@/custom-elements/Collapse';
import { getCSSCustomProp } from '../utils/css';

function init(container: HTMLElement | Document = document) {
    const collapseWrappers = Array.from(container.querySelectorAll('.js-filter-collapse-wrapper'));
    const ROWS_TO_SHOW = 7;

    collapseWrappers.forEach((wrapper) => {
        const content = wrapper.querySelector<HTMLElement>('.js-filter-collapse-content');
        const trigger = wrapper.querySelector<HTMLElement>('.js-filter-collapse-trigger');

        if (content && trigger) {
            const gap = getCSSCustomProp(content, 'gap', 'number') as number;
            const elements = Array.from(content.querySelectorAll<HTMLElement>('.js-filter-collapse-content-item'));
            const elementsHeights = elements.map((item) => item.offsetHeight);
            const maxElementHeight = Math.max(...elementsHeights);
            const maxHeightForCollapsed = maxElementHeight * ROWS_TO_SHOW + gap * (ROWS_TO_SHOW - 1);
            let maxHeight = content.scrollHeight;
            const triggerCollapsedText = trigger.dataset.collapsedText;
            const triggerVisibleText = trigger.dataset.visibleText;
            const triggerTextEl = trigger.querySelector<HTMLElement>('.js-filter-collapse-trigger-text');
            let isCollapsed = false;

            const setCollapsedHeight = () => {
                content.style.maxHeight = `${maxHeightForCollapsed + 6}px`;
            };

            const setVisibleHeight = () => {
                content.style.maxHeight = `${maxHeight}px`;
            };

            const setCollapsedText = () => {
                if (triggerTextEl && triggerCollapsedText) {
                    triggerTextEl.innerHTML = triggerCollapsedText;
                    trigger.dataset.hover = triggerCollapsedText;
                }
            };

            const setVisibleText = () => {
                if (triggerTextEl && triggerVisibleText) {
                    triggerTextEl.innerHTML = triggerVisibleText;
                    trigger.dataset.hover = triggerVisibleText;
                }
            };

            const setCollapsed = () => {
                setCollapsedHeight();
                setCollapsedText();
                isCollapsed = true;
            };

            const setVisible = () => {
                setVisibleHeight();
                setVisibleText();
                isCollapsed = false;
            };

            const setCollapseNeed = () => {
                trigger.removeAttribute('hidden');
                setCollapsed();
            };

            const setCollapseNoNeed = () => {
                trigger.setAttribute('hidden', '');
            };

            const checkNeeds = () => {
                Array.from(content.querySelectorAll<HTMLElement>('.btn')).forEach(
                    (item) => (item.style.overflow = 'hidden'),
                );
                maxHeight = content.scrollHeight;
                Array.from(content.querySelectorAll<HTMLElement>('.btn')).forEach((item) => (item.style.overflow = ''));

                if (maxHeight > maxHeightForCollapsed) {
                    setCollapseNeed();
                } else {
                    setCollapseNoNeed();
                }
            };

            checkNeeds();
            wrapper.addEventListener('search-updated', () => {
                setTimeout(() => {
                    checkNeeds();
                }, 1);
            });
            window.addEventListener('resize', checkNeeds);

            trigger.addEventListener('click', () => {
                if (isCollapsed) {
                    setVisible();
                } else {
                    setCollapsed();
                }
            });
        }

        const collapse = wrapper.closest<Collapse>('app-collapse');
        collapse?.setContentHeightInstant();
    });
}

export default { init };
