export function withLeadingZero(num: number, targetLength = 2) {
    return num.toString().padStart(targetLength, '0');
}

export function trimDecimal(number: number, targetLength = 1) {
    return number.toFixed(targetLength).replace(/\.0$/, '');
}

export const deleteHash = (str: string) => (str.indexOf('#') === -1 ? str : str.substring(0, str.indexOf('#')));

export const deleteGetParams = (str: string) => (str.indexOf('?') === -1 ? str : str.substring(0, str.indexOf('?')));

/**
 * Преобразует секунды в строку вида "mm:ss".
 */
export const formatTimeInSeconds = (seconds: number, targetLength = 2) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds - minutes * 60;

    return isNaN(minutes) || isNaN(remainingSeconds)
        ? null
        : `${withLeadingZero(minutes, targetLength)}:${withLeadingZero(remainingSeconds, 2)}`;
};

/**
 * Форматирует число байтов в строку
 */
export function bytesToSize(bytes: number): string {
    const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

    if (bytes === 0) return '0 bytes';

    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const size = (bytes / Math.pow(1024, i)).toFixed(sizes[i] === 'bytes' ? 0 : 2);

    return `${size} ${sizes[i]}`;
}

export const getNoun = (number: number, one: string, two: string, five: string) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
};
