import Cookies from 'js-cookie';
import delegate from 'delegate';
import axios from 'axios';
import { BaseResponse } from '@/types';
import { removeButtonLoading, setButtonLoading } from '../utils/loading-btn';

const BTN_ACTIVE_CLASS = 'is-in-basket';
const BASKET_COOKIE_NAME = 'BASKET';

function addUniqueItemToArray<T = unknown>(arr: T[], value: T) {
    return [...new Set([...arr, value])];
}

function getBasket(): (string | number)[] {
    const cookie = Cookies.get(BASKET_COOKIE_NAME);
    return JSON.parse(cookie || '[]');
}

function addToBasket(productId: string | number) {
    const basket = addUniqueItemToArray(getBasket(), productId);
    Cookies.set(BASKET_COOKIE_NAME, JSON.stringify(basket), { expires: 9999 });
    return basket;
}

const addedItemPopup = document.querySelector<HTMLElement>('.js-added-item-popup');
const addedItemImage = document.querySelector<HTMLImageElement>('.js-added-item-image');
const addedItemName = document.querySelector<HTMLElement>('.js-added-item-name');
const addedItemPrice = document.querySelector<HTMLElement>('.js-added-item-price');
const closeAddedItem = document.querySelector<HTMLElement>('.js-close-added-item');

let removeAddedPopupTimeout;

if (closeAddedItem) {
    clearTimeout(removeAddedPopupTimeout);
    closeAddedItem.addEventListener('click', hideAdded);
}

function showAdded(image: string, name: string, price: string) {
    if (addedItemPopup) {
        if (addedItemImage) {
            addedItemImage.setAttribute('src', image);
        }

        if (addedItemName) {
            addedItemName.innerHTML = name;
        }

        if (addedItemPrice) {
            addedItemPrice.innerHTML = price;
        }

        addedItemPopup.classList.add('is-visible');
    }
}

function hideAdded() {
    if (addedItemPopup) {
        addedItemPopup.classList.add('is-hiding');
        addedItemPopup.classList.remove('is-visible');

        setTimeout(() => {
            addedItemPopup.classList.remove('is-hiding');
        }, 600);
    }
}

async function init() {
    const cartAction = Array.from(document.querySelectorAll('.js-header-cart-action'));
    const counterElements = Array.from(document.querySelectorAll('.js-cart-counter'));

    const setActualBasketBtnState = (productId: number) => {
        const basket = getBasket().map((id) => String(id));
        Array.from(document.querySelectorAll<HTMLElement>('.js-product-basket-add'))
            .filter((el) => el.dataset.productId === productId.toString())
            .forEach((el) => {
                const { productId } = el.dataset;

                if (productId && basket.includes(productId)) {
                    el.setAttribute('hidden', '');
                    const wrapper = el.closest('.js-product-cart-wrapper');
                    const link = document.createElement('a');
                    link.classList.add('btn', 'btn-gray', 'product-cart-link');
                    link.setAttribute('href', el.dataset.cartUrl ?? '#');
                    const text = document.createElement('span');
                    text.classList.add('btn__text');
                    text.innerHTML = 'In Cart';
                    link.appendChild(text);
                    wrapper?.appendChild(link);
                }
            });
    };

    const setActualBasketCount = () => {
        const currentBasket = getBasket();

        counterElements.forEach((el) => {
            el.textContent =
                currentBasket.length > 0 ? (currentBasket.length > 9 ? '9+' : `${currentBasket.length}`) : '';
        });

        if (currentBasket.length > 0) {
            cartAction.forEach((el) => {
                el.classList.add('is-active');
            });
        } else {
            cartAction.forEach((el) => {
                el.classList.remove('is-active');
            });
        }
    };

    setActualBasketCount();

    Array.from(document.querySelectorAll<HTMLElement>('.js-product-basket-add')).forEach((el) => {
        const id = el.dataset.productId ? parseInt(el.dataset.productId) : null;

        if (typeof id === 'number') {
            setActualBasketBtnState(id);
        }
    });

    delegate(document, '.js-product-basket-add', 'click', async (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const target = event.delegateTarget as HTMLButtonElement;
        const { productId, endpoint } = target.dataset;

        if (productId && endpoint) {
            setButtonLoading(target);

            try {
                const response = await axios.post<
                    BaseResponse<{
                        s: string;
                        added?: {
                            name: string;
                            price: string;
                            image: string;
                        };
                    }>
                >(endpoint, {
                    action: 'add',
                    productIds: [parseInt(productId)],
                });

                if (response.data.status === 'success') {
                    addToBasket(productId);
                    target.classList.add(BTN_ACTIVE_CLASS);
                    setActualBasketCount();
                    setActualBasketBtnState(parseInt(productId));
                    if (response.data?.data?.added) {
                        const { added } = response.data.data;
                        showAdded(added.image, added.name, added.price);
                        removeAddedPopupTimeout = setTimeout(() => {
                            hideAdded();
                        }, 5000);
                    }
                }
            } finally {
                removeButtonLoading(target);
            }
        }
    });
}

export default { init };
