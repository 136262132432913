import Cookies from 'js-cookie';
import { LitPopupElement } from '@/custom-elements/LitPopupElement/LitPopupElement';

const MAIN_DISCOUNT_POPUP_APPEARED_COOKIE_NAME = 'MAIN_DISCOUNT_POPUP_APPEARED';

function init() {
    const mainDiscountPopup = document.querySelector<LitPopupElement>('app-lit-popup.js-main-discount-popup');

    if (mainDiscountPopup && Cookies.get(MAIN_DISCOUNT_POPUP_APPEARED_COOKIE_NAME) !== 'true') {
        setTimeout(() => {
            mainDiscountPopup.open();
            Cookies.set(MAIN_DISCOUNT_POPUP_APPEARED_COOKIE_NAME, 'true', { expires: Infinity });
        }, 5000);
    }
}

export default { init };
